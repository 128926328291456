var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { PayloadHandlerMixin, PROMOTIONAL_PLANNING_PROMO_UPLOAD_EXPORT, FILTERS_EQUAL, UPLOAD_MASS_ACCEPTANCE, UPLOAD_MASS_DELETION } from "@kraftheinz/common";
import { P as PromoUtils } from "./promotion.planning.js";
import { n as normalizeComponent, E as ExportTypes } from "./index.js";
import { U as UploadForm, a as UploadDetails } from "./UploadForm.js";
import "numeral";
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "export-promo" }, [_c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "title": "Export Promo", "visible": _vm.visible, "width": 1e3, "destroy-on-close": "" }, on: { "ok": function($event) {
      return handleSubmit(_vm.handleOk);
    }, "cancel": _vm.handleCancel } }, [!_vm.isExported ? _c("div", [_c("a-row", { attrs: { "gutter": 24, "type": "flex" } }, [_vm.typeExport != 1 ? _c("a-col", { attrs: { "span": 4 } }, [_c("week-picker", { staticClass: "export-promo__week-picker", attrs: { "form-item": "", "label": "From week", "placeholder": "From week", "show-start-of-week": "", "rules": "required", "format-date": "DD-MM-YYYY" }, on: { "change": function($event) {
      return _vm.storeValue("fromDate", $event);
    } } })], 1) : _c("a-col", { attrs: { "span": 4 } }, [_c("select-input", { attrs: { "label": "From year", "data-source": _vm.getListYear(_vm.year - 1), "source-label": "description", "source": "code", "value": _vm.year - 1 }, on: { "change": function($event) {
      return _vm.storeValue("fromYear", $event);
    } } })], 1), _vm.typeExport != 1 ? _c("a-col", { attrs: { "span": 4 } }, [_c("week-picker", { staticClass: "export-promo__week-picker", attrs: { "form-item": "", "label": "To week", "placeholder": "To week", "rules": "required", "show-start-of-week": "", "format-date": "DD-MM-YYYY" }, on: { "change": function($event) {
      return _vm.storeValue("toDate", $event);
    } } })], 1) : _c("a-col", { attrs: { "span": 4 } }, [_c("select-input", { attrs: { "label": "To year", "data-source": _vm.getListYear(_vm.year), "source-label": "description", "source": "code", "value": _vm.year }, on: { "change": function($event) {
      return _vm.storeValue("toYear", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("select-input", { ref: "customer", attrs: { "reference": "promotion-planning.common.customers", "label": "Key Acount", "placeholder": "Key Acount", "source": "id", "source-label": function(val) {
      return _vm.generateLabel(val, [
        "region",
        "keyAccountCode",
        "keyAccount"
      ]);
    } }, on: { "change": function($event) {
      return _vm.storeValue("customerId", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 4 } }, [_c("select-input", { ref: "region", attrs: { "reference": "promotion-planning.common.regions", "placeholder": "Region", "source": "code", "source-label": "description", "label": "Region" }, on: { "change": function(event) {
      return _vm.changeSelectField(event, "region");
    } } })], 1), _c("a-col", { attrs: { "span": 4 } }, [_c("select-input", { ref: "state", attrs: { "placeholder": "State", "source": "code", "data-source": _vm.stateList, "source-label": "description", "label": "State" }, on: { "change": function($event) {
      return _vm.storeValue("state", $event);
    } } })], 1)], 1), _c("a-row", { staticClass: "mt-3", attrs: { "gutter": 24, "type": "flex" } }, [_c("a-col", { attrs: { "span": 8 } }, [_c("label", { style: _vm.radioStyle }, [_vm._v("Export Format")]), _c("a-radio-group", { on: { "change": _vm.onExportTypeChange }, model: { value: _vm.typeExport, callback: function($$v) {
      _vm.typeExport = $$v;
    }, expression: "typeExport" } }, _vm._l(_vm.exportTypes, function(type) {
      return _c("a-radio", { key: type.value, style: _vm.radioStyle, attrs: { "value": type.value, "disabled": !_vm.$can(type.permission, _vm.page) } }, [_vm._v(" " + _vm._s(type.name) + " ")]);
    }), 1)], 1), _c("a-col", { attrs: { "span": 16 } }, [_c("a-row", { attrs: { "gutter": 24, "type": "flex" } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { ref: "cluster", attrs: { "reference": "promotion-planning.common.clusters", "source": "code", "placeholder": "Cluster", "custom-query": "All=false", "source-label": function(val) {
      return _vm.generateLabel(val, ["code", "description"]);
    }, "label": "Cluster" }, on: { "change": function(event) {
      return _vm.changeSelectField(event, "cluster");
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { ref: "category", attrs: { "label": "Category", "placeholder": "Category", "data-source": _vm.categoryList, "source-label": function(val) {
      return _vm.generateLabel(val, ["code", "description"]);
    }, "source": "code" }, on: { "change": function(event) {
      return _vm.changeSelectField(event, "category");
    } } })], 1)], 1), _c("a-row", { staticClass: "mt-3", attrs: { "gutter": 24, "type": "flex" } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { key: "subCategorys", ref: "subCategory", attrs: { "source": "code", "data-source": _vm.subCatList, "placeholder": "Subcategory", "source-label": function(val) {
      return _vm.generateLabel(val, ["code", "description"]);
    }, "label": "Subcategory" }, on: { "change": function(event) {
      return _vm.changeSelectField(event, "subCategory");
    } } })], 1)], 1)], 1)], 1), _c("a-row", { staticClass: "mt-3", attrs: { "gutter": 24, "type": "flex" } }, [_c("a-col", { attrs: { "span": 8 } }, [_c("label", { style: _vm.radioStyle }, [_vm._v("Version")]), _c("checkbox-input", { ref: "isInactive", attrs: { "label": "Active only", "value": true }, on: { "change": function($event) {
      return _vm.storeValue("isInactive", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 16 } }, [_c("select-input", { ref: "ppg", attrs: { "label": "PPG", "source": _vm.typeExport == 1 ? "id" : "entryId", "placeholder": "PPG", "source-label": function(val) {
      return _vm.generateLabel(val, _vm.typeExport == 1 ? ["code", "description"] : ["finYear", "code", "description"]);
    }, "data-source": _vm.ppgList }, on: { "change": function($event) {
      return _vm.storeValue(_vm.typeExport == 1 ? "productId" : "entryId", $event);
    } } })], 1)], 1)], 1) : _c("div", { staticClass: "download-result" }, [_c("a-icon", { attrs: { "type": "cloud-download" } }), _c("br"), _vm.isDownload ? _c("a-spin", { attrs: { "tip": "Preparing download..." } }) : _c("p", { staticStyle: { "color": "green" } }, [_vm._v("Download successful!")]), !_vm.isDownload ? _c("div", [_c("a-icon", { attrs: { "type": "file-excel" } }), _vm._v(" "), _vm.data ? _c("a", { on: { "click": function($event) {
      return _vm.stringToCsvFile(_vm.data.fileName, _vm.data.data, _vm.data.isExcel, _vm.data.contentType);
    } } }, [_vm._v(" " + _vm._s(_vm.data.fileName) + " ")]) : _vm._e()], 1) : _vm._e()], 1), _c("template", { slot: "footer" }, [!_vm.isExported ? _c("div", [_c("a-button", { key: "back", on: { "click": _vm.handleCancel } }, [_vm._v("Cancel")]), _c("a-button", { key: "submit", attrs: { "type": "primary", "loading": _vm.loading, "disabled": !_vm.typeExport }, on: { "click": function($event) {
      return handleSubmit(_vm.handleOk);
    } } }, [_vm._v(" Export ")])], 1) : _c("div", [_c("a-button", { key: "submit", attrs: { "type": "primary" }, on: { "click": _vm.handleCancel } }, [_vm._v(" OK ")])], 1)])], 2)];
  } }]) })], 1);
};
var staticRenderFns$2 = [];
var ExportPromo_vue_vue_type_style_index_0_scoped_true_lang = "";
const apiUrl$2 = "#{VUE_APP_API_URL}#";
const { EXPORT_TYPES, PROMOTION_LIST, PROMOTION_ANALYSIS, DEMANTRA } = ExportTypes;
const __vue2_script$2 = {
  name: "ExportPromo",
  mixins: [PromoUtils, PayloadHandlerMixin],
  inject: ["crud", "can"],
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  provide() {
    return {
      apiUrl: apiUrl$2
    };
  },
  data() {
    const year = this.$moment().year();
    return {
      apiUrl: apiUrl$2,
      loading: false,
      exportTypes: EXPORT_TYPES,
      page: PROMOTIONAL_PLANNING_PROMO_UPLOAD_EXPORT,
      typeExport: null,
      isExported: false,
      isDownload: true,
      data: "",
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px"
      },
      filterFormParam: {},
      categoryApiUrl: `${apiUrl$2}/promotion-planning/common/categories`,
      ppgApiUrl: `${apiUrl$2}/promotion-planning/common/products`,
      entriesUrl: `${apiUrl$2}/promotion-planning/common/entries`,
      stateApiUrl: `${apiUrl$2}/promotion-planning/common/states`,
      subCatApiUrl: `${apiUrl$2}/promotion-planning/common/sub-categories`,
      year,
      categoryList: [],
      ppgList: [],
      stateList: [],
      subCatList: []
    };
  },
  mounted() {
    this.storeValue("isInactive", true);
    this.storeValue("fromYear", this.year - 1);
    this.storeValue("toYear", this.year);
  },
  methods: {
    getListYear(year) {
      return [
        { code: year - 1, description: year - 1 },
        { code: year, description: year },
        { code: year + 1, description: year + 1 }
      ];
    },
    handleCancel() {
      this.$emit("update:visible", false);
      setTimeout(() => {
        this.filterFormParam = {};
        this.typeExport = PROMOTION_LIST;
        this.isExported = false;
        this.isDownload = true;
        this.files = this.errorFiles = this.categoryList = this.subCatList = this.stateList = this.ppgList = [];
      }, 200);
    },
    onExportTypeChange() {
      this.$refs.customer.setValue(void 0);
      if (this.typeExport == DEMANTRA) {
        this.$refs.region.setValue("AU");
        this.changeSelectField("AU", "region");
      } else {
        this.$refs.region.setValue(void 0);
      }
      this.$refs.state.setValue(void 0);
      this.$refs.cluster.setValue(void 0);
      this.$refs.category.setValue(void 0);
      this.$refs.subCategory.setValue(void 0);
      this.$refs.ppg.setValue(void 0);
      this.$refs.isInactive.setValue(true);
      this.filterFormParam = {};
      this.storeValue("isInactive", true);
      this.files = this.errorFiles = this.categoryList = this.subCatList = this.stateList = this.ppgList = [];
    },
    handleOk() {
      let queryExtra = "", exportUrl = "", responseType = "", isExcel = false;
      switch (this.typeExport) {
        case PROMOTION_LIST:
          queryExtra = `&fromYear=${this.filterFormParam.fromYear || this.year - 1}&toYear=${this.filterFormParam.toYear || this.year}`;
          isExcel = false;
          exportUrl = `${apiUrl$2}/promotion-planning/promo-entries/export-list`;
          break;
        case PROMOTION_ANALYSIS:
          queryExtra = `&fromDate=${this.$moment(this.filterFormParam.fromDate).format("YYYY/MM/DD")}&toDate=${this.$moment(this.filterFormParam.toDate).format("YYYY/MM/DD")}`;
          exportUrl = `${apiUrl$2}/promotion-planning/promo-entries/export-details`;
          responseType = "blob";
          isExcel = true;
          break;
        case DEMANTRA:
          queryExtra = `&fromDate=${this.$moment(this.filterFormParam.fromDate).format("YYYY/MM/DD")}&toDate=${this.$moment(this.filterFormParam.toDate).format("YYYY/MM/DD")}&region=${this.filterFormParam.region}`;
          exportUrl = `${apiUrl$2}/promotion-planning/promo-entries/export-demantra`;
          isExcel = false;
          break;
      }
      this.isExported = true;
      this.exportPromoList(this.typeExport, exportUrl, queryExtra, responseType, isExcel);
    },
    exportPromoList(typeExport, exportUrl, queryExtra, responseType, isExcel) {
      this.axios.get(exportUrl + "?" + this.generateFeString({
        ProductId: this.filterFormParam.productId,
        EntryId: this.filterFormParam.entryId,
        Region: typeExport == DEMANTRA ? void 0 : this.filterFormParam.region,
        State: this.filterFormParam.state,
        CustomerId: this.filterFormParam.customerId,
        Cluster: this.filterFormParam.cluster,
        Category: this.filterFormParam.category,
        SubCategory: this.filterFormParam.subCategory,
        IsInactive: this.filterFormParam.isInactive ? false : null
      }) + queryExtra, { responseType }).then((res) => {
        let fileName = res.headers["filename"];
        this.data = {
          data: res.data,
          fileName,
          isExcel,
          contentType: res.headers["content-type"]
        };
        this.stringToCsvFile(fileName, res.data, isExcel, res.headers["content-type"]);
        this.isDownload = false;
      }).catch((err) => {
        this.showErrorMsg(err);
        this.handleCancel();
      });
    },
    storeValue(resourceKey, value) {
      const filterFormParam = this.filterFormParam;
      this.filterFormParam = __spreadProps(__spreadValues({}, filterFormParam), { [resourceKey]: value });
      if (this.typeExport != PROMOTION_LIST && this.filterFormParam.subCategory) {
        this.fetchEntries();
      }
    },
    async changeSelectField(e, name) {
      if (name === "category") {
        this.subCatList = await this.fetchSelectedList(e, this.subCatApiUrl);
        this.subCatList = this.subCatList.filter((e2) => e2.code !== "-1");
      } else if (name === "cluster") {
        this.categoryList = await this.fetchSelectedList(e, this.categoryApiUrl);
        this.categoryList = this.categoryList.filter((e2) => e2.code !== "-1");
      } else if (name === "region") {
        this.stateList = await this.fetchSelectedList(e, this.stateApiUrl);
      } else {
        if (this.typeExport == PROMOTION_LIST) {
          this.ppgList = await this.fetchSelectedList(e, this.ppgApiUrl);
        }
        this.ppgList = this.ppgList.filter((e2) => e2.code !== "0");
        if (this.ppgList.length === 0) {
          this.enabledEntry = true;
          this.activeTabKey = "guardrails";
        }
      }
      this.storeValue(name, e);
    },
    async fetchEntries() {
      this.ppgList = await this.fetchSelectedList(null, this.entriesUrl + "?" + this.generateFeString({
        Region: this.filterFormParam.region,
        State: this.filterFormParam.state,
        CustomerId: this.filterFormParam.customerId,
        FinYear: this.filterFormParam.finYear,
        Cluster: this.filterFormParam.cluster,
        Category: this.filterFormParam.category,
        SubCategory: this.filterFormParam.subCategory
      }) + `&fromDate=${this.$moment(this.filterFormParam.fromDate).format("YYYY/MM/DD")}&toDate=${this.$moment(this.filterFormParam.toDate).format("YYYY/MM/DD")}`, true);
    }
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, "e3ae940c", null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var ExportPromo = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("list-composer", { attrs: { "title": "Upload/Export", "search-by": "UploadFormat,FileName", "ph-search-by": "Search by Upload Format, File Name", "has-active": false }, scopedSlots: _vm._u([{ key: "create-buttons", fn: function() {
    return [_c("a-dropdown", [_c("a-menu", { attrs: { "slot": "overlay" }, on: { "click": _vm.handleMenuClick }, slot: "overlay" }, _vm._l(_vm.uploadTypes, function(field) {
      return _c("a-menu-item", { key: field.key, attrs: { "disabled": field.disabled } }, [_vm._v(_vm._s(field.name))]);
    }), 1), _c("a-button", { attrs: { "type": "default", "icon": "upload" } }, [_vm._v(" Upload "), _c("a-icon", { attrs: { "type": "down" } })], 1)], 1), _c("a-button", { staticClass: "ml-2", attrs: { "type": "default", "icon": "download" }, on: { "click": _vm.exportFile } }, [_vm._v("Export")])];
  }, proxy: true }, { key: "customFilter", fn: function() {
    return [_c("div", { staticClass: "status-filter mr-2" }, [_c("select-filter", { attrs: { "source": "code", "default-active-first-option": "", "source-label": "name", "data-source": _vm.uploadTypesFilter }, on: { "change": function($event) {
      return _vm.onFiltersChange("UploadFormat", $event);
    } } })], 1), _c("div", { staticClass: "status-filter mr-2" }, [_c("select-filter", { attrs: { "source": "code", "default-active-first-option": "", "source-label": "name", "data-source": _vm.months }, on: { "change": function($event) {
      return _vm.onFiltersChange("Month", $event);
    } } })], 1)];
  }, proxy: true }, { key: "action", fn: function(ref) {
    var record = ref.record;
    return [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Detail")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "profile", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onDetailClick(record);
    } } })], 1)];
  } }]) }, [_c("text-field", { key: "UploadFormat", attrs: { "base-url": "/promotion-planning/fixedterms", "data-index": "uploadFormat", "title": "Upload Format", "sorter": true, "width": 150 } }), _c("text-field", { key: "FileName", attrs: { "data-index": "fileName", "title": "File Name", "sorter": true } }), _c("text-field", { key: "CreatedAt", attrs: { "data-index": "createdAt", "title": "Date & Time", "parse": _vm.parseDate, "sorter": true, "width": 150 } }), _c("text-field", { key: "CreatedBy", attrs: { "data-index": "createdBy", "title": "Uploaded by", "sorter": true } }), _c("text-field", { key: "Success", attrs: { "data-index": "success", "title": "Upload Success", "width": 150 } }), _c("tag-field", { key: "Status", attrs: { "color": _vm.getStatusColor, "data-index": "status", "title": "Status", "width": 100 } })], 1), _c("export-promo", { attrs: { "visible": _vm.visibleExport }, on: { "update:visible": function($event) {
    _vm.visibleExport = $event;
  } } }), _c("upload-form", { attrs: { "visible": _vm.visibleUpload, "type": _vm.uploadType }, on: { "update:visible": function($event) {
    _vm.visibleUpload = $event;
  } } }), _c("a-modal", { attrs: { "footer": false, "visible": _vm.visibleUploadDetails }, on: { "cancel": _vm.closeModalUploadDetails } }, [_c("upload-details", { attrs: { "error-files": _vm.uploadsDetail, "title": "Details", "has-description": false } })], 1)], 1);
};
var staticRenderFns$1 = [];
var UploadExport_vue_vue_type_style_index_0_scoped_true_lang = "";
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const uploadTypesFilter = [
  {
    code: "All",
    name: "All format",
    key: "1",
    disabled: false
  },
  {
    code: "Promotion List",
    name: "Promotion List",
    key: "2",
    disabled: false
  },
  {
    code: "Sales Plan",
    name: "Sales Plan",
    key: "3",
    disabled: false
  },
  {
    code: "Price Volume History",
    name: "Price Volume History",
    key: "4",
    disabled: true
  },
  {
    code: "POT",
    name: "POT",
    key: "5",
    disabled: false
  },
  {
    code: "Mass Publish",
    name: "Mass Publish",
    key: "6",
    disabled: false
  },
  {
    code: "Mass Acceptance",
    name: "Mass Acceptance",
    key: "7",
    disabled: false,
    permissionCode: UPLOAD_MASS_ACCEPTANCE
  },
  {
    code: "Mass Deletion",
    name: "Mass Deletion",
    key: "8",
    disabled: false,
    permissionCode: UPLOAD_MASS_DELETION
  }
];
const incompleteStatus = ["Processing", "In Proccess", "In Progress"];
const failedStatus = ["Failed"];
const partiallyCompletedStatus = ["Partially Completed"];
const __vue2_script$1 = {
  name: "UploadExport",
  inject: ["crud"],
  components: {
    ExportPromo,
    UploadForm,
    UploadDetails
  },
  data() {
    const months = [
      {
        code: 0,
        name: "This month"
      },
      ...Array.from(Array(12).keys()).map((_, i) => ({
        code: i + 1,
        name: this.$moment().month(i).format("MMMM")
      }))
    ];
    return {
      apiUrl: apiUrl$1,
      filtersLoaded: 0,
      months,
      uploadType: 1,
      uploadsDetail: {},
      uploadTypesFilter,
      uploadTypes: uploadTypesFilter.filter((item) => item.key !== "1"),
      visibleExport: false,
      visibleUpload: false,
      visibleUploadDetails: false
    };
  },
  computed: {
    uploadList() {
      return this.crud.getList();
    }
  },
  watch: {
    uploadList(newVal) {
      if (this.fetchListInterval)
        this.fetchListInterval = clearInterval(this.fetchListInterval);
      if (newVal.some((val) => incompleteStatus.includes(val.status)))
        this.fetchListInterval = setInterval(this.crud.fetchList.bind(this.crud), 1e4);
    }
  },
  created() {
    this.onFiltersChange("Month", { operator: FILTERS_EQUAL, value: 0 });
  },
  beforeDestroy() {
    if (this.fetchListInterval)
      this.fetchListInterval = clearInterval(this.fetchListInterval);
  },
  methods: {
    exportFile() {
      this.visibleExport = true;
    },
    handleMenuClick(event) {
      this.uploadType = parseInt(event.key);
      this.visibleUpload = true;
    },
    parseDate(value) {
      if (!value)
        return "";
      return this.$moment(value).format("DD/MM/YYYY HH:mm");
    },
    onDetailClick(record) {
      this.axios.get(`${this.apiUrl}/promotion-planning/uploads/${record.id}`).then((res) => {
        this.uploadsDetail = res.data;
        this.visibleUploadDetails = true;
      }).catch((err) => {
        this.$notification.error({
          message: this._.get(err, "response.data.message") || "Errors encountered"
        });
      });
    },
    getStatusColor(value) {
      if (incompleteStatus.includes(value) || partiallyCompletedStatus.includes(value)) {
        return "primary-6";
      }
      if (failedStatus.includes(value)) {
        return "error-6";
      }
      return "success-6";
    },
    onFiltersChange(key, filter) {
      let { value } = filter;
      this.filtersLoaded++;
      this.crud.setFilter(key, filter);
      this.crud.setPagination({ page: 1 });
      switch (key) {
        case "UploadFormat":
          if (value === "All")
            this.crud.deleteFilter(key);
          break;
        case "Month":
          if (value === 0) {
            this.crud.setFilter(key, __spreadProps(__spreadValues({}, filter), {
              value: this.$moment().month() + 1
            }));
          }
          break;
      }
      this.filtersLoaded >= 2 && this.crud.fetchList();
    },
    closeModalUploadDetails() {
      this.visibleUploadDetails = false;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "953fc6c6", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var UploadExport = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "promotion-planning.common.regions", "api-url": _vm.apiUrl, "resource-id-name": "code" } }), _c("resource", { attrs: { "name": "promotion-planning.common.customers", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "promotion-planning.common.clusters", "api-url": _vm.apiUrl, "resource-id-name": "code" } }), _c("resource", { attrs: { "name": "promotion-planning.uploads", "api-url": _vm.apiUrl } }, [_c("upload-export")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = "";
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    UploadExport
  },
  data() {
    return {
      apiUrl,
      UploadExport,
      itemsMenu: [
        {
          title: "Administration",
          path: ""
        },
        {
          title: "Upload/Export",
          path: "/promotional-planning/upload-export"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
